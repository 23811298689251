import { CSSProperties, HTMLAttributes, ReactNode, useState } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import { ITextKeys } from 'src/context/Language/types';
import useLanguage from 'src/context/Language/useLanguage';
import { onlyEmailRegex, phoneSpecialCharacters, usPhoneRegex } from 'src/helpers/formRejex';
import Text, { TextColor, TextVariant } from '../Text';
import { Container, FLoatLabel, HelperText, InputContainer, RequiredIndicator } from './styles';

export enum InputTextType {
  text = 'text',
  email = 'email',
  number = 'number',
  password = 'password',
  tel = 'tel',
  date = 'date'
}

export type InputTextOnChangeProps = {
  field: string;
  value: string | boolean;
};

export interface InputError {
  field: string;
  message: ITextKeys | string;
  errorParam?: string;
}

export interface InputTextProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  name: string;
  type: InputTextType;
  label: string;
  placeholder?: string;
  value?: string;
  isDisabled?: boolean;
  isReadonly?: boolean;
  alwaysShowLabel?: boolean;
  includeRequiredIndicator?: boolean;
  labelStyle?: CSSProperties;
  readOnlyColor?: keyof typeof TextColor;
  required?: boolean;
  endAndorment?: ReactNode;
  multiline?: boolean;
  rows?: number;
  defaultValue?: string;
  errors: InputError[];
  config?: RegisterOptions;
  onChange?: ({ field, value }: InputTextOnChangeProps) => void;
}

const DEFAULT_VALIDATIONS = {
  onlyNumbers: () => true,
  minLength: () => true,
  maxLength: () => true,
  format: () => true
};

const InputText = (props: InputTextProps) => {
  const {
    name = 'inputName',
    type = InputTextType.text,
    label,
    placeholder = label,
    value = '',
    isDisabled = false,
    isReadonly = false,
    alwaysShowLabel = false,
    includeRequiredIndicator = false,
    labelStyle,
    readOnlyColor,
    required,
    config,
    onChange,
    endAndorment = null,
    multiline = false,
    rows,
    defaultValue = '',
    errors,
    ...htmlAttrs
  } = props;

  const {
    register,
    control,
    setValue,
    formState: { errors: validationErrors },
    getValues
  } = useFormContext();
  const { translate: t } = useLanguage();
  const text = useWatch({
    control,
    name,
    defaultValue
  });

  const [valueText, setValueText] = useState(value);

  const setFocusedOn = () => {
    const container = document.getElementById(`${name}_container`);
    if (container && !isReadonly) {
      container.setAttribute('data-focused', 'true');
    }
  };

  const setFocusedOff = () => {
    const container = document.getElementById(`${name}_container`);
    if (container && !isReadonly) {
      container.setAttribute('data-focused', 'false');
    }
  };

  const errorMessage = ((): string | null => {
    const value = getValues(name);
    if (required && value !== null && !value) {
      return t('validation_complete_this_field');
    }

    if (validationErrors) {
      const errorField = validationErrors[name];
      if (errorField) {
        if (errorField?.message) {
          return errorField.message.toString();
        }
      }
    }

    return null;
  })();

  const configType = () => {
    if (type === InputTextType.email) {
      return {
        ...config,
        maxLength: {
          value: 100,
          message: '100 Characters Maximum'
        },
        pattern: {
          value: onlyEmailRegex,
          message: 'Invalid email address'
        }
      };
    }

    if (type === InputTextType.tel) {
      return {
        ...config,
        validate: {
          ...DEFAULT_VALIDATIONS,
          phoneValidation: (value: string) =>
            // only run validation if there is a value inserted in the field
            value
              ? usPhoneRegex.test(value) ||
                'Invalid phone number. Make sure to enter a valid US phone.'
              : undefined,
          minLength: (value: string) =>
            value
              ? value.trim().replace(phoneSpecialCharacters, '').length >= 10 ||
                'Phone number should have 10 digits minimum.'
              : undefined,
          maxLength: (value: string) =>
            value
              ? value.trim().replace(phoneSpecialCharacters, '').length <= 15 ||
                'Phone number should have 15 digits maximum.'
              : undefined
        }
      };
    }

    return config;
  };
  const configUpd = configType();

  const inputProps = register(name, {
    ...configUpd,
    disabled: isDisabled,
    onBlur: () => {
      if (valueText) {
        setValueText(valueText.trim());
        setValue(name, valueText.trim());
      }
      setFocusedOff();
    }
  });

  const Label = () => {
    const LabelComp = (
      <FLoatLabel aria-invalid={errorMessage ? 'true' : 'false'} style={labelStyle}>
        <Text variant={TextVariant.small} color={TextColor.primary}>
          {label}
          {required && includeRequiredIndicator && <RequiredIndicator>*</RequiredIndicator>}
        </Text>
      </FLoatLabel>
    );

    if (alwaysShowLabel) return LabelComp;

    if (text === undefined) return null;

    if (isReadonly && text !== '' && valueText !== '') return LabelComp;

    if (text === '' && valueText === undefined) return null;

    if (text !== '' && valueText === '') return LabelComp;

    if (valueText !== '') return LabelComp;

    if (text !== '' && valueText === undefined) return LabelComp;

    return null;
  };

  const onChanging = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = e.target.value;
    setValueText(text);
    setValue(name, text);
    if (onChange) {
      onChange({ field: name, value: text });
    }
  };

  const attrs = {
    ...inputProps,
    defaultValue,
    id: name,
    className: 'input-form-control',
    autoComplete: 'off',
    type,
    placeholder: placeholder,
    'aria-invalid': !!errorMessage,
    'aria-required': !!required,
    'aria-disabled': isDisabled,
    'aria-placeholder': placeholder,
    'aria-label': name,
    'data-isclickable': typeof props.onClick === 'function',
    disabled: isDisabled,
    readOnly: isReadonly,
    onChange: onChanging,
    onFocus: setFocusedOn
  };
  return (
    <Container {...htmlAttrs} aria-invalid={!!errorMessage}>
      <InputContainer
        hasError={!!errorMessage}
        readOnlyColor={readOnlyColor}
        id={`${name}_container`}
        isDisabled={!!isDisabled}
        className="input-form"
        endAndorment={Boolean(endAndorment)}
        multiline={multiline}
        aria-disabled={!!isDisabled}
      >
        <Label />
        {multiline ? <textarea {...attrs} rows={rows} /> : <input {...attrs} />}
        {endAndorment}
      </InputContainer>
      {!isDisabled && errorMessage && (
        <HelperText className="HelperText">
          <Text variant={TextVariant.small} color={TextColor.error}>
            {errorMessage}
          </Text>
        </HelperText>
      )}
    </Container>
  );
};

export default InputText;
