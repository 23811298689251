import React, { useState } from 'react';
import { BoxProof } from 'src/components/DUP/molecules/BoxProof';
import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { ButtonGroup } from 'src/components/molecules/ButtonGroup';
import useLanguage from 'src/context/Language/useLanguage';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import { makeRequest } from 'src/hooks/useResource';
import {
  CreateProofRequest,
  DupApplicationType,
  JobErrorType,
  Proof,
  ProofResponse,
  UnauthenticateSessionProofType
} from 'src/types/api';
import { Container, IconBorder } from './styles';

// Helpers
import { getDocumentTypes } from 'src/features/DUP/helpers/getDocumentTypes';

export type UploadFileProps = {
  type: DupApplicationType;
  refresh?: () => Promise<Proof[]>;
  proofs: Proof[];
  setProofIsProcessing: (isProcessing: boolean) => void;
};

const UploadFiles: React.FC<UploadFileProps> = ({
  type = DupApplicationType.UNAUTHENTICATED_USER,
  refresh,
  proofs,
  setProofIsProcessing
}) => {
  const { translate: t } = useLanguage();
  const [uploadingFiles, setUploadingFiles] = React.useState<ProofForBox[]>([]);

  const [documentTypeSelected, setDocumentTypeSelected] = useState<UnauthenticateSessionProofType>(
    UnauthenticateSessionProofType.Paystub
  );

  const handleUploadFiles = async (proofsToUpload: CreateProofRequest[]) => {
    setProofIsProcessing(true);

    const failedDocs: ProofForBox[] = [];
    for (const { upload, type } of proofsToUpload) {
      const formData = new FormData();
      formData.append('upload', upload);
      formData.append('type', type);
      const response = await makeRequest<
        ProofResponse | { error: string; failedChecks: JobErrorType[] }
      >(`/session/documents?checks=true`, 'POST', formData, true);

      if ('error' in response) {
        const overSizeError =
          response.error === 'File too large, only files under 25MB will be uploaded';

        failedDocs.push({
          id: upload.name,
          type,
          jobs_error: overSizeError ? ['PDFOver25MBError'] : response.failedChecks,
          thumb: ''
        });
      }
    }

    await refresh?.();
    setProofIsProcessing(false);

    return failedDocs;
  };

  const handleReplaceFileEvent = async (
    e: React.ChangeEvent<HTMLInputElement>,
    replaceProof: ProofForBox
  ) => {
    const chosenFile = Array.prototype.slice.call(e.target.files)[0];
    const replacingSavedProof = !replaceProof.jobs_error;
    const type = replaceProof.type;

    const placeholderFile: ProofForBox = {
      id: replaceProof.id,
      type,
      thumb: '',
      jobs_error: [],
      isReplacing: replacingSavedProof,
      isLoading: true
    };

    setUploadingFiles((prevState) => [
      ...prevState.filter((f) => f.id !== placeholderFile.id),
      placeholderFile
    ]);

    if (replacingSavedProof) {
      await makeRequest(`/session/documents/${replaceProof.id}`, 'DELETE');
    }

    const failedFiles = await handleUploadFiles([{ upload: chosenFile, type }]);

    setUploadingFiles((prevState) => [
      ...prevState.filter((f) => f.id !== placeholderFile.id),
      ...failedFiles
    ]);
  };

  const handleFileEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    const type = documentTypeSelected || UnauthenticateSessionProofType.Paystub;

    const placeholderFiles = chosenFiles.map(
      (upload: File): ProofForBox => ({
        id: upload.name,
        type: type,
        thumb: '',
        jobs_error: [],
        isReplacing: false,
        isLoading: true
      })
    );

    setUploadingFiles((prevState) => [...prevState, ...placeholderFiles]);

    const failedFiles = await handleUploadFiles(
      chosenFiles.map((upload) => ({ upload, type })) satisfies CreateProofRequest[]
    );

    setUploadingFiles((prevState) => [
      ...prevState.filter((p) => !placeholderFiles.includes(p)),
      ...failedFiles
    ]);
  };

  // The proofs array are the fully uploaded documents. Mix in documents we're
  // currently uploading to create the displayed set. Some uploading documents
  // replace existing documents.
  const displayed: ProofForBox[] = [
    ...proofs.map((p) => uploadingFiles.find((u) => u.isReplacing && u.id === p.id) || p),
    ...uploadingFiles.filter((u) => !u.isReplacing)
  ];

  const isCell = displayed.length > 0;

  const UploadFileBox = () => {
    return (
      <Container type={type} data-type={type} isCell={isCell}>
        {isCell ? (
          <Row>
            <Text
              color={TextColor.initial}
              variant={TextVariant.h3}
              isBold
              align={TextAlign.center}
            >
              {t('dup_proof_another_file_title')}
            </Text>
          </Row>
        ) : (
          <>
            <Row>
              <Row.Col size={1} alignContent="center">
                <IconBorder type={type}>
                  <Icon icon="icon-folder-open" />
                </IconBorder>
              </Row.Col>
            </Row>
            <Row>
              <Row.Col size={1}>
                <Gap height={1} />
                <Text
                  color={TextColor.initial}
                  variant={TextVariant.h3}
                  isBold
                  align={TextAlign.center}
                >
                  {t('dup_proof_no_files_yet')}
                </Text>
                <Gap height={1} />
                <Text color={TextColor.initial} align={TextAlign.center}>
                  {t('dup_proof_upload', FILES_TO_UPLOAD.ALLOW_FORMAT)}
                </Text>
              </Row.Col>
            </Row>
          </>
        )}
        <Gap height={2} />
        <Row className="SelectFile">
          <Row.Col size={1} alignSelf="flex-end">
            <ButtonGroup
              label={t('dup_proof_button_select_doc_type')}
              buttons={getDocumentTypes(t)}
              value={documentTypeSelected}
              onClick={({ value }) =>
                setDocumentTypeSelected(value as UnauthenticateSessionProofType)
              }
            />
          </Row.Col>
          <Row.Col size={1} alignSelf="flex-end">
            <input
              type="file"
              name="file"
              id="file"
              data-testid="upload_button"
              className="inputFile"
              accept={FILES_TO_UPLOAD.ALLOW_FORMAT}
              onChange={async (e) => await handleFileEvent(e)}
              multiple
            />
            <label htmlFor="file" className="inputFileButton">
              <Text color={TextColor.primary}>{t('dup_proof_button_upload')} </Text>
              <Icon icon="icon-cloud-upload" data-testid="upload-icon" />
            </label>
          </Row.Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      {isCell ? (
        <Row columns={3} gap={3} wrap="wrap">
          {displayed.map((proof, index) => {
            return (
              <Row.Col size={1} key={`${proof.id}-${index}`}>
                <BoxProof
                  num={index + 1}
                  proof={proof}
                  type={type}
                  refresh={refresh}
                  isLoading={proof.isLoading}
                  onReplaceWithFile={(e) => void handleReplaceFileEvent(e, proof)}
                  onRemoveFailedDoc={() =>
                    proof?.jobs_error?.length
                      ? setUploadingFiles(uploadingFiles.filter((p) => p.id !== proof.id))
                      : null
                  }
                />
              </Row.Col>
            );
          })}
          <Row.Col size={1}>
            <Row justify="center">
              <UploadFileBox />
            </Row>
          </Row.Col>
        </Row>
      ) : (
        <Row justify="center">
          <UploadFileBox />
        </Row>
      )}
    </>
  );
};

export default UploadFiles;
