/**
 * This hook is used to wrap an API call using axios and handle the response.
 * It will return the response data, loading state, and error state.
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { useSessionStorage } from 'src/hooks/useSessionStorage';

export type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface DefaultResponseData {
  content?: string;
}

export type DefaultBodyData = Record<string, string> | object | null;

export interface ApiHookParams<B> {
  url: string;
  method?: FetchMethod;
  headers?: Record<string, string> | object;
  authType?: 'unauthenticated' | 'authenticated';
  loadsOnMount?: boolean;
  body?: B;
}

interface ApiHookResponse<T> {
  data: T | null;
  loading: boolean;
  error: boolean | string;
  // This exported method is used to manually trigger the API request if loadsOnMount is set to false
  makeRequest: () => void;
}

/**
 * Method for making a request to an API endpoint.
 *
 * @param url | the url to make the request to
 * @param method | The HTTP method to use
 * @param headers | The headers to send with the request
 * @param authType | The type of authentication to use
 * @param loadsOnMount | Whether or not the request should be made when the component it is used in mounts
 */
function useApi<T = DefaultResponseData, B = DefaultBodyData>({
  url,
  method = 'GET',
  headers = {},
  authType = 'unauthenticated',
  loadsOnMount = true,
  body
}: ApiHookParams<B>): ApiHookResponse<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<boolean | string>(false);
  const [sessionToken] = useSessionStorage(UNAUTHENTICATED_SESSION_TOKEN_KEY, '');
  const [requestShouldLoad, setRequestShouldLoad] = useState<boolean>(loadsOnMount);
  const loadingRef = useRef(false);

  // use use memo to ensure that the headers are only updated when the authHeader or headers change
  const updatedHeaders = useMemo(
    () => ({
      ...(authType === 'unauthenticated'
        ? {
            'x-unauthenticated-session-token': sessionToken
          }
        : {}),
      ...headers
    }),
    [headers, authType, sessionToken]
  );

  // Serializing the headers ensures that the useEffect hook will
  // only re-run when the headers actually change.
  const serializedHeaders = useMemo(() => JSON.stringify(updatedHeaders), [updatedHeaders]);

  useEffect(() => {
    if (!requestShouldLoad) return;
    if (loadingRef.current) return;
    loadingRef.current = true;
    const source = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      cancelToken: source.token,
      url,
      method: method.toLowerCase() as 'get' | 'post' | 'put' | 'delete',
      headers: JSON.parse(serializedHeaders)
    };
    if (method === 'POST' && body) {
      requestConfig.data = body;
    }
    axios(requestConfig)
      .then((res: AxiosResponse<T>) => {
        if (res.data !== null && res.data !== undefined) {
          setData(res.data);
        }
        loadingRef.current = false;
        setRequestShouldLoad(false);
      })
      .catch((err) => {
        loadingRef.current = false;
        setError(`Error: ${err}`);
        setRequestShouldLoad(false);
      });
    return () => {
      source.cancel();
    };
  }, [url, method, serializedHeaders, body, requestShouldLoad]);

  return {
    data,
    loading: loadingRef.current,
    error,
    makeRequest: () => setRequestShouldLoad(true)
  };
}

export default useApi;
