import { useCallback, useEffect, useState } from 'react';
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import ApplicationInfo from 'src/components/DUP/molecules/WizardSubmit/ApplicationInfo';
import useLanguage from 'src/context/Language/useLanguage';
import { useArgyleLink } from 'src/hooks/useArgyleLink';
import useAxApi from 'src/hooks/useAxApi';
import { useSnackbarProvider } from 'src/hooks/useSnackbarProvider';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { StyledButton as Button } from 'src/pages/DUPPage/styles';
import { Proof } from 'src/types/api';
import { ActionContainer, Container, Terms, TermsRow } from './styles';

const getDisabledTextKey = (
  isSubmittable: boolean,
  isProofsHaveErrors: boolean,
  proofIsProcessing: boolean
) => {
  if (!isSubmittable) {
    return 'dup_footer_pre_submit_incomplete_form';
  }

  if (isProofsHaveErrors) {
    return 'dup_footer_pre_submit_proofs_has_error';
  }

  if (proofIsProcessing) {
    return 'dup_footer_pre_submit_proofs_processing';
  }
  return '';
};

const WizardSubmit = (props: DupScreenStepProps & { proofs: Proof[] }) => {
  const { application, onFinished, information, setActiveStep, proofs } = props;
  const { translate: t } = useLanguage();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const { accountsConnected, isLoadingAccounts, isDeletingAccounts } = useArgyleLink();
  const [isTOSChecked, setIsTOSChecked] = useState(false);

  const {
    data: axData,
    makeRequest: makeAxRequest,
    loading: isSubmitting,
    error
  } = useAxApi<{ applicantId: string }, { proofs: { sourceName: string }[] }>({
    route: 'applicant/submit',
    method: 'POST',
    loadsOnMount: false,
    body: {
      proofs: proofs.map((proof) => ({
        sourceName: proof.fileName || ''
      }))
    }
  });

  const handleError = useCallback(() => {
    showSnackbar(VariantType.error, t('dup_submission_error_title'), SnackTypes.none);
  }, [showSnackbar, t, VariantType.error, SnackTypes.none]);

  useEffect(() => {
    if (error) {
      handleError();
      return;
    }
    if (axData) {
      if ('error' in axData || Boolean(error)) {
        handleError();
      } else if ('applicantId' in axData) {
        onFinished();
      }
    }
  }, [axData, onFinished, error, handleError]);

  const [hasPayrollAccounts, setHasPayrollAccounts] = useState(false);

  useEffect(() => {
    if (!isLoadingAccounts && !isDeletingAccounts) {
      setHasPayrollAccounts(!!accountsConnected?.length);
    }
  }, [accountsConnected, isLoadingAccounts, isDeletingAccounts]);

  const isProofsHaveErrors = proofs.some((proof) => Boolean(proof.jobs_error?.length));
  const hasDocuments = !!proofs.length; // Simplified check for documents

  const disableTextKey = getDisabledTextKey(
    isTOSChecked && (hasDocuments || hasPayrollAccounts),
    isProofsHaveErrors,
    false
  );

  const submitData = () => {
    if (!isSubmitting) {
      makeAxRequest();
    }
  };

  return (
    <Container>
      <ApplicationInfo
        application={application}
        setActiveStep={setActiveStep}
        proofs={proofs}
        hasPayrollAccounts={hasPayrollAccounts}
        accountsConnected={accountsConnected}
      />
      <TermsRow>
        <Checkbox
          key={`terms_${isTOSChecked}_`}
          onClick={(obj) => setIsTOSChecked(obj.isChecked)}
          name="tosAndPP"
          showLabel={false}
          value="yes"
          label={t('dup_footer_i_agree_to_the_terms_and_pp')}
          isChecked={isTOSChecked}
        />
        <Terms className="label">
          {t('dup_footer_i_agree_to_the')}
          <Link to={information.url_terms_of_service} isExternal target={LinkTarget.blank}>
            {t('dup_wizard_terms_of_service')}
          </Link>
          {t('dup_footer_and')}
          <Link to={information.url_privacy_policy} isExternal target={LinkTarget.blank}>
            {t('dup_footer_privacy_policy')}
          </Link>
        </Terms>
      </TermsRow>
      <ActionContainer justify="space-between">
        <Button
          name="back"
          onClick={() => {
            setActiveStep(2);
          }}
          variant={ButtonVariant.outline}
        >
          {t('dup_button_label_back')}
        </Button>
        <ToolTip
          direction={ToolTipDirection.top_left}
          content={disableTextKey ? t(disableTextKey) : ''}
        >
          <Button
            name="submit"
            onClick={() => submitData()}
            isDisabled={
              !isTOSChecked ||
              !(hasDocuments || hasPayrollAccounts) ||
              isSubmitting ||
              isProofsHaveErrors
            }
            color={!isTOSChecked ? ButtonColor.disabled : ButtonColor.primary}
            variant={ButtonVariant.contained}
          >
            {t('dup_footer_button_submit')}
          </Button>
        </ToolTip>
      </ActionContainer>
    </Container>
  );
};

export default WizardSubmit;
